import i18n from "@/plugins/i18n";

export const columns = [
  {
    label: 'product',
    field: 'product.brandName',
    width: '40%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'tagItemPrice',
    field: 'tagItemPrice.tagItem.value',
    width: '30%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'price',
    field: 'price',
    width: '5%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'direction',
    field: 'quantity',
    formatRowValue: true,
    formatFn: (value) => {
      return value.quantity >=0 ? i18n.t('store.in') : i18n.t('store.out');
    },
    width: '10%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'quantityShort',
    field: 'quantity',
    formatRowValue: true,
    formatFn: (value) => {
      return Math.abs(value.quantity);
    },
    width: '10%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
]
