<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('storeItemsUpdates') }} {{ date }}</h2>
      <grid-view-table
        :id="'store-item-change-log'"
        :columns="columns"
        :path="'store-item-change-log'"
        :extra-params="extraParams"
        :rowStyleClassFn="rowStyleClassFn"
      />
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import GridViewTable from '../../components/GridViewTable';
  import { APIService } from '../../services/api';
  import { columns } from './grid-views/store-change-log-items';

  export default {
    name: 'ViewStoreChangeLogItem',
    components: {
      GridViewTable,
    },
    props: {
      storeChangeLogId: {
        type: String,
        required: true,
      },
    },
    data: () => {
      return {
        storeChangeLog: null,
        columns,
      }
    },
    methods: {
      rowStyleClassFn(row) {
        console.log('row', row);
        return row.quantity >=0 ? 'store-in' : 'store-out';
      },
    },
    computed: {
      date: function() {
        return this.storeChangeLog ? moment(this.storeChangeLog.updatedAt).format('DD.MM.YYYY HH:mm:ss') : ''
      },
      extraParams: function () {
        return `&join=prices&join=store&join=barcode&join=product&join=tagItemPrice&join=tagItemPrice.tagItem&join=storeChangeLog&filter=storeChangeLog.id||eq||${this.storeChangeLogId}`
      }
    },
    async beforeMount() {
      try {
        this.storeChangeLog = await APIService.get(`store-change-log/${this.storeChangeLogId}`);
      } catch (error) {
        console.error('Store change log record not found');
      }
    },
  }
</script>
